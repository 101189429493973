/* global styles */

.container {
  margin-top: 20px;
}

.btn {
  padding: 10px !important;
}
.canary-h1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  color: #1a45fa;
}

.canary-p-blue {
  font-size: 18px;
  color: #1a45fa;
  font-weight: 600;
  font-family: 'Inter';
}

body p {
  font-family: 'Inter', sans-serif !important;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
}

.canary-welcome-header {
  font-weight: 900;
  font-size: 24px;
  color: #7627ff;
}

.canary-btn-red {
  background: #ff0000;
  font-size: 18px;
  font-weight: 700;
}

.canary-btn-blue {
  background: #1a45fa;
  font-size: 18px;
  font-weight: 700;
}

.canary-btn-blue-ghost {
  background: white;
  color: #1a45fa;
  font-weight: 700;
}

.canary-navbar-header {
  background: #010a46;
  box-shadow: 0px 2px 4px rgba(63, 120, 229, 0.4);
}

.canary-navbar-header .navbar-nav .nav-link {
  color: white;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #d9d9d9;
  border-color: #d9d9d9;
}

.canary-nav-bar {
  background: #010a46;
  box-shadow: 0px 2px 4px rgba(63, 120, 229, 0.4);
}

.canary-navbar-toggler-icon {
  background-image: url('/src/images/svg/menu.svg') !important;
  width: 71px;
}

.canary-nav-bar .navbar-nav .nav-link {
  color: white;
  font-size: 18px;
  font-weight: 200;
  border-bottom: 1px solid #ffffff;
}

.canary-nav-bar .navbar-nav .nav-link:hover {
  color: white !important;
  text-decoration: underline !important;
}

.nav-link-user-setting {
  font-size: 14px;
  color: #bec9e1;
}

.nav-ui-seperator {
  margin-top: 100px;
}

.nav-wallet {
  margin-top: 20px;
  border: 1px solid #ffffff;
  border-radius: 4px;
  color: white;
  padding: 10px;
}

.nav-wallet a {
  color: white;
  text-decoration: none;
}

.nav-wallet:hover {
  cursor: pointer;
}

.bottom-grey-section {
  background: #525252;
  margin-top: 12px;
  margin-left: -12px;
  margin-right: -12px;
  margin-bottom: -12px;
}

.bottom-grey-section .nav-item {
  padding-left: 12px;
  padding-right: 12px;
}

/* end global styles */

.price-h1 {
  font-size: 24px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  color: #1a45fa;
}

.price-h1 span {
  font-size: 10px;
  font-weight: 300;
}

.price-explainer {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #333333;
}

.price-ul-header {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #1a45fa;
}

.price-box li {
  font-family: 'Inter', sans-serif !important;
  font-size: 18px;
  color: #333333;
}

.price-disclaimer {
  margin-top: 10px;
  color: #bec9e1;
  font-size: 14px;
}

.price-col {
  padding-left: 45px;
  padding-right: 45px;
}

.plan-info {
  margin-left: -10px;
}

.confirm-details .form-label {
  color: #333333;
}

.resend-code {
  color: #1a45fa;
  font-size: 14px;
  font-weight: 400;
}

.resend-code:hover {
  cursor: pointer;
  text-decoration: underline;
}

.fa-instructions {
  list-style: none;
}

/* OTP */

#otp {
  margin-bottom: 20px;
}

#otp .form-control {
  -webkit-transition: none;
  transition: none;
  width: 45px;
  height: 45px;
  text-align: center;
}

#otp .form-control:focus {
  color: #3f4254;
  background-color: #ffffff;
  border-color: #884377;
  outline: 0;
}

#otp .form-control.form-control-solid {
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  color: #3f4254;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
}

#otp .form-control.form-control-solid:active,
#otp .form-control.form-control-solid.active,
#otp .form-control.form-control-solid:focus,
#otp .form-control.form-control-solid.focus {
  background-color: #ebedf3;
  border-color: #ebedf3;
  color: #3f4254;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
}

/* manage plan */

.manageplan-border {
  border: 1px solid #1a45fa;
  padding: 10px;
}

.manageplan-text-error {
  margin-top: 10px;
  font-size: 14px;
  color: #1a45fa;
}

.manageplan-text-error:hover {
  cursor: pointer;
}
.manageplan-info {
  font-size: 18px;
}

.manageplan-red-error {
  color: #d92222;
  font-size: 18px;
}

/* dashboard */

.dashboard-warning {
  color: #d92222;
}

/* backup wallet */

.assetlist-ul {
  list-style: none;
  margin-left: -2em;
}

.assetlist-ul li {
  margin-top: 5px;
  margin-bottom: 5px;
}

.token-logo img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

/* spinner */

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
